import consumer from "./consumer"

if(location.pathname.includes('admin') && location.pathname.includes('buildings') && !location.pathname.includes('rooms')){
  const regex = /\d+/g
  const buildingId = location.pathname.match(regex)[0]
  consumer.subscriptions.create({ channel: "BuildingsChannel", building: buildingId }, {
    connected() {
      // Called when the subscription is ready for use on the server
    },
  
    disconnected() {
      // Called when the subscription has been terminated by the server
    },
  
    received(data) {
      // Called when there's incoming data on the websocket for this channel
      // updating video
      const rooms = Object.keys(data.rooms).map( key => { return {...data.rooms[key]} } )
      rooms.map( curRoom => {
        let room = document.querySelector(`#${curRoom.name.split(' ').join('_')}`)
        if(room){
          let sourcesSelect = room.querySelector('.room_sources')
          sourcesSelect.value = parseInt(curRoom.video_source)
          
          let options = curRoom.video_options.map( source => 
            `<option value='${source.id}' ${parseInt(curRoom.video_source) == source.id ? 'selected' : ''}>${source.name}</option>` 
          ).join()

          sourcesSelect.innerHTML = "<option value=''>No Source Selected</option>" + options

          // update room volume
          const slider = room.querySelector('.room-volume-slider')
          const volumeViewer = room.querySelector('.volume')

          slider.value = parseInt(curRoom.volume_value)
          volumeViewer.innerText = curRoom.volume_value

          // update audio controls
          const audioControls = room.querySelector('.audio_controls')

          const controls = curRoom.audio_controls.map( control => {
            const audioOptions = control.options.map( option => {
              return `
                <button 
                  type='button' 
                  class='btn btn-success ${option.id === parseInt(control.current_selection) ? 'btn-selected' : null}'
                  onclick='changeAudioOption(event, ${control.id }, ${option.id})'
                >
                  ${option.name}
                </button>
              `
            } )

            return `<p>${control.name} - set to: ${audioOptions.join(' ')}</p>`
          } )
          audioControls.innerHTML = controls.join(' ')
          // <p>
          //   <%= audio.name %> - set to: 
          //   <% audio.audio_options.each do |option| %>
          //     <button 
          //       type='button' 
          //       class='btn btn-success <%= option.id == audio.currently_selected.to_i ? 'btn-selected' : nil %>'
          //       onclick='changeAudioOption(event, <%= audio.id %>, <%= option.id %>)'
          //     >
          //       <%= option.name %>
          //     </button>
          //   <% end %>
          // </p>
        }
      })

      // updating walls
      const walls = Object.keys(data.walls).map( key => { return {...data.walls[key]} } )
      walls.map( curWall => {
        // grab button -> grab the span
        // update open closed
        const button = document.querySelector(`#${curWall.name.split(' ').join('_')}`)
        const openClosedSpan = [...button.children].find(node => node.classList.contains('openClosed'))

        openClosedSpan.innerText = curWall.open ? 'Open' : 'Closed'
      })
    }
  });
}


